const eventBus = {
  on(event: string, callback: (e: any) => void) {
    // Event object will be received in the callback here, passed data will have to be available at 'detail'
    document.addEventListener(event, callback);
  },
  dispatch(event: string, data: any) {
    // Data is added to the 'detail' of the event
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  off(event: string, callback: (e: any) => void) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
