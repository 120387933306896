import React from 'react';

import ModalContext, { ModalConfig } from '@contexts/ModalContext';

import Modal from '../Modal';

interface ModalContainerProps {
  children: JSX.Element;
}

const ModalContainer: React.FC<ModalContainerProps> = ({
  children,
}: ModalContainerProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<ModalConfig | null>(null);
  const openModal = (config: ModalConfig) => {
    setConfig(config);
    setIsOpen(true);
  };
  return (
    <ModalContext.Provider
      value={{
        isOpen,
        openModal,
      }}
    >
      {children}
      <Modal isOpen={isOpen} {...config} closeModal={() => setIsOpen(false)} />
    </ModalContext.Provider>
  );
};

export default ModalContainer;
