import React from 'react';

import { useReactiveVar } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';

import {
  subscribedDashboardAppsVar,
  wickedApiVar,
} from 'src/apollo/reactiveVars';

import type { WickedApi } from '../types';

const useCheckWickedApi = (wickedApi: WickedApi) => {
  const subscribedDashboardApps = useReactiveVar(subscribedDashboardAppsVar);
  React.useEffect(() => {
    // Redirect to root URL if not subscribed to the dashboard app
    if (!subscribedDashboardApps.includes(wickedApi)) {
      window.location.href = '/';
    }
    // Set wickedApi
    wickedApiVar(wickedApi);
    // Set Sentry tags
    Sentry.setTag('wickedApi', wickedApi);

    console.log({ wickedApi: wickedApi });
  }, [subscribedDashboardApps]);
};

export default useCheckWickedApi;
