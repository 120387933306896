import { palette as Pallete } from './../../../@types/palette';
const Theme = (palette: Pallete) => ({
  type: palette.type,
  color: {
    // common colors
    successLighten: '#4e9b3950',
    success: '#4e9b39',
    warningLighten: '#e78c3b50',
    warning: '#e78c3b',
    errorLighten: '#e94c4a50',
    error: '#e94c4a',
    info: '#91d5ff',
    infoLighten: '#e6f7ff',
    errorMessage: '#f33a3a',

    // gray
    gray50: palette.gray[50],
    gray100: palette.gray[100],
    gray200: palette.gray[200],
    gray300: palette.gray[300],
    gray400: palette.gray[400],
    gray500: palette.gray[500],
    gray600: palette.gray[600],
    gray700: palette.gray[700],
    gray800: palette.gray[800],
    gray900: palette.gray[900],

    // success
    success300: palette.success[300],
    success500: palette.success[500],
    success700: palette.success[700],
    success900: palette.success[900],

    // danger
    danger300: palette.danger[300],
    danger500: palette.danger[500],
    danger700: palette.danger[700],
    danger900: palette.danger[900],

    // warning
    warning300: palette.warning[300],
    warning500: palette.warning[500],
    warning700: palette.warning[700],
    warning900: palette.warning[900],

    // info
    info300: palette.info[300],
    info500: palette.info[500],
    info700: palette.info[700],
    info900: palette.info[900],

    primary50: palette.primary[50],
    primary100: palette.primary[100],
    primary200: palette.primary[200],
    primary300: palette.primary[300],
    primary400: palette.primary[400],
    primary500: palette.primary[500],
    primary600: palette.primary[600],
    primary700: palette.primary[700],
    primary800: palette.primary[800],
    primary900: palette.primary[900],

    // accent
    accent300: palette.accent[300],
    accent500: palette.accent[500],
    accent700: palette.accent[700],
  },
  body: {
    background: palette.primary[700],
    textColor: palette.gray[50],
  },
  notification: {
    background: '#0d2039',
    text: '#ffffff',
    textSecondary: '#b6b6b6',
  },
  button: {
    background: 'transparent',
    text: '#ffffff',
    border: '#6be3e6',
    borderHover: '#70eef0',
  },
  topbar: {
    bgNormal: palette.primary[800],
    text: palette.gray[300],
    iconColor: palette.gray[300],
    // iconHoverColor: '',
    // iconActiveColor: '',
    // iconHoverBg: '',
    // iconActiveBg: '',
    iconHover: '#354151',
    iconActive: '#00ffff',
  },
  table: {
    headerBackground: palette.primary[600],
    headerTextColor: palette.gray[500],
    bodyBackground: palette.primary[200],
    bodyTextColor: palette.gray[200],
    bodyBorderFocus: palette.accent[500],
    contextHover: palette.primary[300],
    menu: palette.primary[800],
  },
  datePicker: {
    normal: palette.gray[900],
    hover: palette.gray[400],
    active: palette.accent[700],
    presentMonthText: palette.gray[300],
    otherMonthText: palette.gray[900],
    selectedWeekBG: palette.primary[200],
    daysHeader: palette.accent[700],
    arrowsTop: palette.gray[400],
    monthHeader: palette.gray[50],
    modalBG: palette.primary[900],
  },
  timePicker: {
    normal: palette.gray[900],
    hover: palette.gray[400],
    active: palette.accent[700],
    text: palette.gray[300],
    hoverText: palette.gray[50],
    background: palette.primary[900],
    hoverBackground: palette.primary[200],
    highlight: palette.accent[700],
  },
  buttonWithIcon: {
    border: palette.gray[700],
    textNormal: palette.gray[700],
    textHover: palette.gray[500],
    textActive: palette.accent[500],
    backgroundNormal: palette.primary[600],
    backgroundHover: palette.primary[600],
    backgroundActive: palette.primary[200],
  },
  stickyNotification: {
    text: palette.gray[300],
    bg: palette.primary[500],
    highlightSuccess: palette.success[300],
    highlightError: palette.danger[300],
    highlightWarning: palette.warning[300],
    highlightInfo: palette.info[300],
  },
  toastNotification: {
    text: palette.gray[50],
    bgSuccess: palette.success[700],
    bgError: palette.danger[700],
    bgWarning: palette.warning[700],
    bgInfo: palette.info[700],
  },
  sidebar: {
    appIcon: palette.appIcon,
    bgNormal: palette.primary[800],
    bgHover: palette.primary[400],
    bgActive: palette.primary[100],
    textNormal: palette.gray[700],
    textHover: palette.gray[500],
    textActive: palette.gray[200],
    highlightActive: palette.accent[700],
  },
  modal: {
    bg: palette.primary[500],
    bgFooter: palette.primary[600],
    textHeading: palette.gray[500],
    textContent: palette.gray[50],
    textButton: palette.gray[700],
    textButtonHover: palette.gray[300],
    textButtonActive: palette.accent[700],
    bgButtonHover: palette.primary[300],
    borderButton: palette.gray[700],
  },
  feedbackModal: {
    bg: palette.primary[500],
    bgFooter: palette.primary[600],
    selectionButtonOutline: palette.accent[500],
    buttonTextSelected: palette.gray[50],
    headingTextColor: palette.gray[500],
    // selectionButtonHover: '#0c77ac',
    // selectionButtonActive: '#3399cc',
    // inputOutline: '#e5e5e8',
  },
  form: {
    groupHeader: {
      bg: palette.primary[200],
      textColor: palette.gray[500],
      iconColor: palette.accent[700],
      borderLeftColor: palette.accent[700],
    },
    mandatoryStarColor: palette.warning[500],
    dropdown: {
      bg: palette.primary[900],
      bgHover: palette.primary[300],
      // simple: {},
      complex: {
        headingColor: palette.accent[500],
        textColor: palette.gray[300],
        selectedHeadingColor: palette.accent[700],
        selectedTextColor: palette.gray[50],
        borderLeftColor: palette.accent[700],
        bg: palette.primary[900],
        bgHover: palette.primary[300],
      },
    },
  },
  tag: {
    noDocuments: palette.gray[500],
    digitized: palette.info[500],
    queued: palette.info[300],
    pendingApproval: palette.accent[300],
    processed: palette.success[500],
    partiallyProcessed: palette.warning[300],
    notProcessed: palette.danger[300],
    approved: palette.accent[700],
    tms: '#eacc0e',
  },
  rightSidebar: {
    bg: palette.primary[800],
    bgHover: palette.primary[800],
    bgSelected: palette.primary[400],
    iconColor: palette.gray[700],
    iconHover: palette.accent[300],
    iconSelected: palette.accent[300],
    dividerColor: palette.gray[900],
  },
  sideDrawer: {
    bgColor: palette.primary[800],
  },
  profilePanel: {
    bgColor: palette.primary[800],
    hoverColor: palette.primary[400],
    activeColor: palette.primary[100],
    textHeading: palette.gray[300],
    textDesc: palette.gray[900],
    iconColor: palette.gray[200],
    dividerColor: palette.accent[700],
  },
  documentGalleryPage: {
    headerText: palette.gray[400],
    bgColor: palette.primary[800],
    selectedViewIcon: palette.accent[700],
    selectedViewText: palette.gray[400],
    disabledViewicon: palette.gray[900],
    disabledViewText: palette.gray[900],
    tabHeader: {
      tabPaneBgColor: palette.primary[600],
      countBgColor: palette.gray[800],
      countText: '#bcbcbc',
      textColor: palette.gray[800],
      textHover: palette.gray[50],
      textActive: palette.gray[50],
      iconColor: palette.gray[900],
      iconHover: palette.gray[50],
      iconActive: palette.gray[50],
    },
  },
  dropdownMenu: {
    bgColor: palette.primary[900],
    bgHoverColor: palette.primary[300],
    normalText: palette.gray[300],
    hoverText: palette.gray[50],
    divider: '#172a46',
  },
  selectDropdown: {
    bgColor: palette.primary[900],
    bgHoverColor: palette.primary[300],
    normalText: palette.gray[400],
    headingText: palette.gray[900],
    activeColor: palette.accent[700],
    scrollThumb: palette.primary[50],
  },
  jobCard: {
    bgColor: palette.primary[800],
    imgBgColor: '#00091a',
    durationBgColor: palette.primary[600],
    durationText: palette.gray[400],
    iconColor: palette.gray[900],
  },
  jobScroll: {
    popoverBackground: palette.primary[900],
    popoverHover: palette.primary[200],
  },
  home: {
    inputBg: palette.primary[200],
  },
  unassigned: {
    joblistBg: palette.primary[600],
    border: palette.primary[50],
    bgColor: palette.primary[800],
  },
  fontFamily: 'Muli, arial',
});

export default Theme;
