import { makeVar } from '@apollo/client';

import type { ImageCredentials, User, WickedApi } from 'src/global/types';

export const accessTokenVar = makeVar<string | null>(null);
export const userVar = makeVar<User | null>(null);
export const wickedAppVar = makeVar<string>('');
export const wickedApiVar = makeVar<WickedApi | ''>('');
export const subscribedDashboardAppsVar = makeVar<WickedApi[]>([]);
export const multipleDocumentsVar = makeVar<boolean>(false);
export const isSubscribedToSplitVar = makeVar<boolean>(true);
export const imageCredentialsVar = makeVar<ImageCredentials | null>(null);
