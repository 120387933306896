import ReactGA from 'react-ga';

export const initGA = (): any => {
  if (String(process.env.NEXT_PUBLIC_ANALYTICS_ID).length > 0) {
    console.log('GA init');
    ReactGA.initialize(String(process.env.NEXT_PUBLIC_ANALYTICS_ID), {
      debug: false,
    });
  }
};

export const logPageView = (): any => {
  console.log(`Logging pageview for ${window.location.pathname}`);
  if (String(process.env.NEXT_PUBLIC_ANALYTICS_ID).length > 0) {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }
};

export const logEvent = (
  args: ReactGA.EventArgs,
  trackerNames?: ReactGA.TrackerNames | undefined
): any => {
  if (String(process.env.NEXT_PUBLIC_ANALYTICS_ID).length > 0) {
    ReactGA.event(args, trackerNames);
  }
};

export const logException = (description = '', fatal = false): any => {
  if (String(process.env.NEXT_PUBLIC_ANALYTICS_ID).length > 0 && description) {
    ReactGA.exception({ description, fatal });
  }
};
