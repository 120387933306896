import React from 'react';

type ThemeToggleContextProps = {
  isDarkTheme: boolean;
  toggleTheme: any;
};

const ThemeToggleContext = React.createContext<
  Partial<ThemeToggleContextProps>
>({});

export default ThemeToggleContext;
