import { palette } from './../../../@types/palette';
export const ligthPallete: palette = {
  type: 'light',
  primary: {
    50: '#2A3A50',
    100: '#122037',
    200: '#0E2544',
    300: '#0E1E3D',
    400: '#0C1729',
    500: '#0A1E3A',
    600: '#071530',
    700: '#07152A',
    800: '#000D26',
    900: '#040F23',
  },
  accent: {
    300: '#7DC6FF',
    500: '#8AB4F8',
    700: '#3FE7E7',
  },
  hover: {
    300: '#0E1E3D',
  },
  highlight: {
    100: '#FFFF33',
  },
  gray: {
    50: '#FFFFFF',
    100: '#F2F2F2',
    200: '#E5E5E5',
    300: '#DDE0E8',
    400: '#D4D5D6',
    500: '#D1D1D1',
    600: '#C6C7C8',
    700: '#C0C4C8',
    800: '#B6B9BC',
    900: '#989898',
  },
  success: {
    300: '#32F62B',
    500: '#26C400',
    700: '#20A600',
    900: '#00730C',
  },
  danger: {
    300: '#FF2D39',
    500: '#E02424',
    700: '#D90E0E',
    900: '#850002',
  },
  warning: {
    300: '#FF9A39',
    500: '#F5881F',
    700: '#E26F00',
    900: '#B53200',
  },
  info: {
    300: '#7DC6FF',
    500: '#42A5F2',
    700: '#0072CC',
    900: '#00488F',
  },
  appIcon: '#60E4FF',
};

export const darkPallete: palette = {
  type: 'dark',
  primary: {
    50: '#2A3A50',
    100: '#122037',
    200: '#0E2544',
    300: '#0E1E3D',
    400: '#0C1729',
    500: '#0A1E3A',
    600: '#071530',
    700: '#07152A',
    800: '#000D26',
    900: '#040F23',
  },
  accent: {
    300: '#7DC6FF',
    500: '#8AB4F8',
    700: '#3FE7E7',
  },
  hover: {
    300: '#0E1E3D',
  },
  highlight: {
    100: '#FFFF33',
  },
  gray: {
    50: '#FFFFFF',
    100: '#F2F2F2',
    200: '#E5E5E5',
    300: '#DDE0E8',
    400: '#D4D5D6',
    500: '#D1D1D1',
    600: '#C6C7C8',
    700: '#C0C4C8',
    800: '#B6B9BC',
    900: '#989898',
  },
  success: {
    300: '#32F62B',
    500: '#26C400',
    700: '#20A600',
    900: '#00730C',
  },
  danger: {
    300: '#FF2D39',
    500: '#E02424',
    700: '#D90E0E',
    900: '#850002',
  },
  warning: {
    300: '#FF9A39',
    500: '#F5881F',
    700: '#E26F00',
    900: '#B53200',
  },
  info: {
    300: '#7DC6FF',
    500: '#42A5F2',
    700: '#0072CC',
    900: '#00488F',
  },
  appIcon: '#60E4FF',
};
