import React, { useState, useEffect, useContext } from 'react';

const PING_RESOURCE = '/api/ready';
const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 10_000;

const timeout = (time: number, promise: Promise<any>) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'));
    }, time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine;

  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, {
        method: 'GET',
        signal,
      })
    );
    return true;
  } catch (error) {
    // Error Log
    console.error(error);

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
};

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider = ({ children }: any) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

  const checkStatus = async () => {
    console.log('Checking status');
    const online = await checkOnlineStatus();
    console.log('Online status', online);
    setOnlineStatus(online);
    return online;
  };

  useEffect(() => {
    // Start polling if Offline
    if (!onlineStatus) {
      const id = setInterval(async () => {
        const isOnline = await checkStatus();
        if (isOnline) {
          clearInterval(id);
        }
      }, onlinePollingInterval);
    }
  }, [onlineStatus]);

  useEffect(() => {
    const handleOfflineEvent = () => {
      setOnlineStatus(false);
    };

    window.addEventListener('offline', handleOfflineEvent);

    return () => {
      window.removeEventListener('offline', handleOfflineEvent);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  return useContext(OnlineStatusContext);
};

export default useOnlineStatus;
