import React from 'react';

import { Global, css, ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import Head from 'next/head';
// import StyledTopbar from '../modules/Topbar';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ThemeToggleContext from '@contexts/ThemeToggleContext';
import { ligthPallete, darkPallete } from '@theme/palette';
import Theme from '@theme/theme';
import useDarkMode from 'lib/use-dark-mode';
import { OnlineStatusProvider } from 'src/global/hooks/useOnlineStatus';

const StyledMain = styled.main`
  // margin-top: 5em;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const MainLayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

interface Props {
  title?: string;
  children: JSX.Element;
}

function MainLayout(props: Props): JSX.Element {
  const darkMode = useDarkMode(true, {});
  const currentPalette = darkMode.value ? darkPallete : ligthPallete;
  const theme = Theme(currentPalette);

  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <Head>
        <title>KoiReader {props.title ? '- ' + props.title : ''}</title>
      </Head>
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Muli:wght@200;400;500;600;700&display=swap');

          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          html {
            overflow: hidden;
            overflow-x: hidden;
            font-size: 14px;
          }

          ::-webkit-scrollbar {
            width: 0; /* Remove scrollbar space */
            background: transparent; /* Optional: just make scrollbar invisible */
          }

          // Remove up/down arrows from input type numbers
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
          body {
            background-color: ${theme.body.background};
            color: ${theme.body.textColor};
            font-family: ${theme.fontFamily};
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${theme.body.textColor};
          }

          // ToastNotification || message component
          .success-toast .ant-message-notice-content {
            color: ${theme.toastNotification.text};
            background: ${theme.toastNotification.bgSuccess};
          }
          .error-toast .ant-message-notice-content {
            color: ${theme.toastNotification.text};
            background: ${theme.toastNotification.bgError};
          }
          .warning-toast .ant-message-notice-content {
            color: ${theme.toastNotification.text};
            background: ${theme.toastNotification.bgWarning};
          }
          .info-toast .ant-message-notice-content {
            color: ${theme.toastNotification.text};
            background: ${theme.toastNotification.bgInfo};
          }
          .ant-message-custom-content {
            display: flex;
            align-items: center;
            // justify-content: center;
            text-align: left;
          }
          .ant-message-custom-content span {
            width: 95%;
          }
          .ant-message-custom-content svg {
            width: 2.5em;
            height: 2.5em;
            margin-right: 0.5em;
          }

          // Sticky notification
          .success-sticky-notification {
            margin-right: 3em;
            // width: auto !important;
            max-width: 50em;
            color: ${theme.toastNotification.text} !important;
            background: ${theme.stickyNotification.bg} !important;
            border-left: 0.3em solid
              ${theme.stickyNotification.highlightSuccess};
          }
          .success-sticky-notification .ant-notification-notice-message {
            color: ${theme.stickyNotification.highlightSuccess};
          }
          // .success-sticky-notification
          //   .ant-notification-notice-description
          //   span {
          //   color: ${theme.stickyNotification.highlightSuccess};
          // }

          .error-sticky-notification {
            margin-right: 3em;
            // width: auto !important;
            max-width: 50em;
            color: ${theme.toastNotification.text} !important;
            background: ${theme.stickyNotification.bg} !important;
            border-left: 0.3em solid ${theme.stickyNotification.highlightError};
          }
          .error-sticky-notification .ant-notification-notice-message {
            color: ${theme.stickyNotification.highlightError};
          }
          // .error-sticky-notification .ant-notification-notice-description span {
          //   color: ${theme.stickyNotification.highlightError};
          // }

          .warning-sticky-notification {
            margin-right: 3em;
            // width: auto !important;
            max-width: 50em;
            color: ${theme.toastNotification.text} !important;
            background: ${theme.stickyNotification.bg} !important;
            border-left: 0.3em solid
              ${theme.stickyNotification.highlightWarning};
          }
          .warning-sticky-notification .ant-notification-notice-message {
            color: ${theme.stickyNotification.highlightWarning};
          }
          // .warning-sticky-notification
          //   .ant-notification-notice-description
          //   span {
          //   color: ${theme.stickyNotification.highlightWarning};
          // }

          .info-sticky-notification {
            margin-right: 3em;
            // width: auto !important;
            max-width: 50em;
            color: ${theme.toastNotification.text} !important;
            background: ${theme.stickyNotification.bg} !important;
            border-left: 0.3em solid ${theme.stickyNotification.highlightInfo};
          }
          .info-sticky-notification .ant-notification-notice-message {
            color: ${theme.stickyNotification.highlightInfo};
          }
          // .info-sticky-notification .ant-notification-notice-description span {
          //   color: ${theme.stickyNotification.highlightInfo};
          // }

          .ant-notification-close-x {
            color: ${theme.stickyNotification.text};
          }
          .ant-notification-notice-close-x {
            color: ${theme.stickyNotification.text};
          }
          .ant-notification-notice-icon svg {
            width: 2em !important;
            height: 2em !important;
            margin-right: 0.5em;
          }
          .ant-notification-notice-description {
            width: 90%;
            float: right;
          }
          .ant-notification-notice-message {
            width: 90%;
            float: right;
          }

          // TODO - remove this later
          .active-dropzone {
            background: ${theme.color.primary50};
          }
          .trashbin-dropzone {
            .active-icon {
              display: none;
            }
            .normal-icon {
              // display: none;
            }
          }
          .active-trashbin-dropzone {
            .normal-icon {
              display: none;
            }
            .active-icon {
              display: inline;
            }
          }
          .group-dropzone {
          }
          .group-dropzone-active {
            .overlay-drop-to-merge {
              display: flex !important;
            }
          }

          .doc-trim-popover {
            .ant-popover-inner-content {
              padding: 0 !important;
            }
            .ant-popover-arrow-content {
              background: ${theme.color.gray300} !important;
            }
          }
        `}
      />
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          {isMounted && (
            <ThemeToggleContext.Provider
              value={{
                isDarkTheme: darkMode.value,
                toggleTheme: darkMode.toggle,
              }}
            >
              <OnlineStatusProvider>
                <MainLayoutContainer>
                  {/* <StyledTopbar /> */}
                  <StyledMain>{props.children}</StyledMain>
                </MainLayoutContainer>
              </OnlineStatusProvider>
            </ThemeToggleContext.Provider>
          )}
        </DndProvider>
      </ThemeProvider>
    </>
  );
}

export default MainLayout;
