import React from 'react';

import ModalContext, { ModalConfig } from '@contexts/ModalContext';

const useModal = () => {
  const ctx = React.useContext(ModalContext);
  const modal = (config: ModalConfig) => {
    if (typeof ctx.openModal === 'function') {
      ctx.openModal(config);
    }
  };
  return {
    modal,
  };
};

export default useModal;
