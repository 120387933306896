export const VALID_FILE_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/tiff',
];

export const KOIREADER_SUPPORT_EMAIL = 'support@koireader.com';

export const MAX_FILE_SIZE_UPLOAD_LIMIT = 8_000_000;

export const DOCUMENT_COLORS = [
  '#8d7fff',
  '#ff4eb8',
  '#76ffc4',
  '#ff3d6b',
  '#0075fa',
  '#ffe4b4',
  '#faa72b',
  '#00a0d0',
  '#f5aed6',
  '#ff5500',
  '#aaff68',
  '#a5edff',
  '#e6ffab',
  '#ffa8a8',
  '#eb6dff',
  '#cdcdcd',
  '#ae43ff',
  '#ff6972',
  '#3bdaf3',
  '#ffe24e',
];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT = 'MMM D, YYYY';
export const TIME_FORMAT = 'HH:mm';
export const MINIMUM_DATE_TIMESTAMP = 946_688_400_000;

export enum JobStatus {
  all = 'all',
  queued = 'queued',
  digitized = 'digitized',
  approved = 'approved',
  tms = 'in_third_party_process',
  processed = 'processed',
  notProcessed = 'not_processed',
  // pendingApproval = 'pending_approval',
  // partiallyProcessed = 'partially_processed',
}

export enum Events {
  jobCreated = 'jobCreated',
  jobDeleted = 'jobDeleted',
  formRefresh = 'formRefresh',
  tableOpen = 'tableOpen',
  tableClosed = 'tableClosed',
  documentImageRefresh = 'documentImageRefresh',
  documentAssigned = 'documentAssigned',
  updateJobCount = 'updateJobCount',
  imageCredentialsExpired = 'imageCredentialsExpired',
  subscriptionResponse = 'subscriptionResponse',
}

export enum FormGroupLabels {
  basicInformation = 'Basic Information',
  containerDetails = 'Container Details',
  tables = 'Tables',
  containerTable = 'Container Table',
  addresses = 'Addresses',
  events = 'Events',
  chassisDetails = 'Chassis Details',
  location = 'Location',
  routing = 'Routing',
  chargeables = 'Chargeables',
  shippingInformation = 'Shipping Information',
  appointmentDetails = 'Appointment Details',
}

export enum SettingsTabs {
  businessRules = 'businessRules',
}

export enum AppGroupNames {
  smartWarehousing = 'Smart Warehousing',
  smartYards = 'Smart Yards',
  smartPorts = 'Smart Ports',
  industrialAutomation = 'Industrial Automation',
  thirdPartyApps = 'Third Party Apps',
  documentDigitalization = 'Document Digitalization',
  omniverse = 'Omniverse',
  insights = 'Insights',
}

export enum AppNames {
  cartAudit = 'Cart Audit',
  labelScan = 'Label Scan',
  conveyorBeltScan = 'Conveyor Belt Scan',
  inventoryOptimization = 'Inventory Optimization',
  visionPicking = 'Vision Picking',
  itemDimensioning = 'Item Dimensioning',
  warehouseSafetySurveillance = 'Warehouse Safety Surveillance',
  dockDoorPlanning = 'Dock Door Planning',
  yardCheck = 'Container Yard Check',
  fmcsaCompliance = 'FMCSA Compliance',
  epod = ' EPOD',
  gigo = 'GIGO',
  assetTracking = 'Asset Tracking',
  containerOCR = 'Container OCR',
  portSafetySurveillance = 'Safety Surveillance',
  damageDetection = 'Damage Detection',
  qrBarcodeScan = 'QR/Barcode Scan',
  activityRecognition = 'Activity Recognition',
  skuRecognition = 'SKU Recognition',
  realWear = 'RealWear',
  teamViewer = 'TeamViewer',
  deliveryOrder = 'Delivery Order',
  freightForwarding = 'Freight Forwarding',
  industrialOmniverse = 'Industrial Omniverse',
  analytics = 'Analytics',
}
