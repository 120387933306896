import React from 'react';

import { ApolloError, gql, useQuery } from '@apollo/client';

import eventBus from '@utils/eventBus';
import { Events } from 'src/global/constants';

import { ImageCredentials } from '../types';
import { imageCredentialsVar, wickedAppVar } from './../../apollo/reactiveVars';

interface QueryResult {
  getCredsForImages: ImageCredentials;
}

const GET_IMAGE_CREDENTIALS = gql`
  query getCredsForImages($wickedApp: String!, $wickedApi: String!) {
    getCredsForImages(wickedApp: $wickedApp, wickedApi: $wickedApi) {
      region
      bucket
      credentials {
        AccessKeyId
        SecretAccessKey
        SessionToken
        Expiration
      }
    }
  }
`;

const useImageCredentials = (wickedApi: string) => {
  const { data, refetch } = useQuery<QueryResult>(GET_IMAGE_CREDENTIALS, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    pollInterval:
      (Number.parseInt(
        String(process.env.NEXT_PUBLIC_PRESIGN_FETCH_EXPIRY || '1800')
      ) -
        10) *
      1000,
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
    variables: {
      wickedApp: wickedAppVar(),
      wickedApi: wickedApi,
    },
  });

  const updateImageCredentials = () => {
    console.warn('Updating Image Credentials!');
    refetch();
  };

  // Fetch on mount
  React.useEffect(() => {
    refetch();

    // Handle image credentials expired event
    eventBus.on(Events.imageCredentialsExpired, updateImageCredentials);
    return () => {
      eventBus.off(Events.imageCredentialsExpired, updateImageCredentials);
    };
  }, []);

  // Update image credentials reactive var
  React.useEffect(() => {
    if (!data?.getCredsForImages) return;
    imageCredentialsVar(data?.getCredsForImages);
  }, [data]);
};

export default useImageCredentials;
