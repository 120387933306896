import React from 'react';

export interface ModalConfig {
  title?: string;
  text?: string;
  type?: 'success' | 'warning' | 'error' | 'info';
  okText?: string | null;
  cancelText?: string | null;
  onOk?(): void;
  onCancel?(): void;
  icon?: React.ReactNode;
  closable?: boolean;
  width?: number;
  centered?: boolean;
  maskClosable?: boolean;
}

type ModalContextState = {
  isOpen: boolean;
  openModal: (config: ModalConfig) => void;
};

const ModalContext = React.createContext<Partial<ModalContextState>>({});

export default ModalContext;
